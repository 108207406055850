import { ConfigProvider, Layout, Flex, Table, Button, Input, Modal, Form, Space} from 'antd';
import { GlobalInfoProvider } from '../GlobalInfoContext';
import en_GB from 'antd/locale/en_GB';
import { useNavigate } from 'react-router-dom';
import { UserAvatar } from '../components/user/UserAvatar';
import { useState, useContext, useEffect, useRef } from 'react'
import { jumpToLabellingPage, jumpToValidationPage } from "../lib/browser-utils";
import { FileImageOutlined, UserOutlined, CheckCircleOutlined, 
    InfoCircleOutlined, RightOutlined, UnorderedListOutlined, 
    HistoryOutlined, FileDoneOutlined, TeamOutlined } from '@ant-design/icons';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, 
    LineElement, Title, Tooltip, ArcElement, BarElement, Legend, Filler } from 'chart.js';
import { jumpToLoginPage } from "../lib/browser-utils";
import { userApi } from "../api/user/user-api"
import { projectApi } from "../api/project/project-api"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import useColumnSearchProps from '../components/table/WildFilter'
import { loginApi } from "../api/login/login-api";

import { GlobalInfoContext } from "../GlobalInfoContext";

import "./style.css"
import { labellingApi } from '../api/labelling-api';
import Validation from './validation';

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const BASE_URL = process.env.REACT_APP_BASE_URL;

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export default function AccountDetail() {
    const navigate = useNavigate();
    const goHomePage = () => {
        navigate("/");
    };

    const globalInfo = useContext(GlobalInfoContext);
    if (globalInfo.user.email == "") {
        jumpToLoginPage();
    }

    const [collapsed, setCollapsed] = useState(false);
    const [currentPage, setCurrentPage] = useState('historyData');
    const [managedUser, setManagedUser] = useState('')
    const [appliedProject, setAppliedProject] = useState('')
    const [accountUpdateRst, setAccountUpdateRst] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [currentManagedUser, setCurrentManagedUser] = useState(null);
    const [currentApplyProject, setCurrentApplyProject] = useState(null);
    const [permissionCheckLoading, setPermissionCheckLoading] = useState(false);
    const [showLabellingPage, setShowLabellingPage] = useState(false);
    const [accountFields, setAccountFields] = useState();
    const [isPhoneCheckSuccess, setIsPhoneCheckSuccess] = useState(true);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
    const [checkCaptchaRst, setCheckCaptchaRst] = useState(true);
    const [isOldPasswordCorrect, setIsOldPasswordCorrect] = useState(true);
    const [showChangePasswordClose, setShowChangePasswordClose] = useState(false);

    const manageTableRef = useRef(null);
    const [manageTableScrollY, setManageTableScrollY] = useState(0);

    const projectTableRef = useRef(null);
    const [projectTableScrollY, setProjectTableScrollY] = useState(0);

    const userRole = globalInfo.user.userRole;

    const [allImagesNum, setAllImagesNum] = useState(0);
    const [allLabelledImagesNum, setAllLabelledImagesNum] = useState(0);
    const [allLabelledImagesNumByUser, setAllLabelledImagesNumByUser] = useState(0);
    const [labelledImagesListByUser, setLabelledImagesListByUser] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [allUsersLabelCountMap, setAllUsersLabelCountMap] = useState({});
    const [allUsersLabelCount, setAllUsersLabelCount] = useState([]);
    const [allUsersName, setAllUsersName] = useState([]);

    const listManagerData = async () => {
        const managerData = await projectApi.listManagerData({userId: globalInfo.user.userId});
        return managerData;
    }

    const listProjects = async () => {
        const projectList = await projectApi.listProjects({userId: globalInfo.user.userId});
        return projectList;
    }

    const getAllImagesNum = async () => {
        const allImagesNum = await labellingApi.getAllImagesNum({projectId: 1})
        return allImagesNum;
    }

    const getAllLabelledImagesNum = async () => {
        const allLabelledImagesNum = await labellingApi.getAllLabelledImagesNum({projectId: 1})
        return allLabelledImagesNum
    }

    const getAllLabelledImagesNumByUser = async () => {
        const allLabelledImagesNumByUser = await labellingApi.getAllLabelledImagesNumByUser({projectId: 1})
        return allLabelledImagesNumByUser
    }

    const getLabelledImagesListByUser = async () => {
        const labelledImagesListByUser = await labellingApi.getLabelledImagesListByUser({projectId: 1})
        return labelledImagesListByUser
    }

    const getAllUsersLabelCountMap = async () => {
        const countMap = await labellingApi.getAllUsersLabelCount({projectId: 1})
        return countMap
    }

    useEffect(() => {
        if (currentPage === 'userManagement') {
            const parentDivHeight = manageTableRef.current ? manageTableRef.current.clientHeight : 0;
            setManageTableScrollY(parentDivHeight-150);
        }
    }, [currentPage, managerList]);

    useEffect(() => {
        if (currentPage === 'projectList') {
            const parentDivHeight = projectTableRef.current ? projectTableRef.current.clientHeight : 0;
            setProjectTableScrollY(parentDivHeight-150);
        }
    }, [currentPage, projectList]);

    useEffect(() => {
        const fetchData = async () => {
            if (globalInfo.user.phoneNumber) {
                setAccountFields([
                    { name: ['firstName'], value: globalInfo.user.firstName },
                    { name: ['lastName'], value: globalInfo.user.lastName },
                    { name: ['address'], value: globalInfo.user.address },
                    { name: ['phoneNumber'], value: globalInfo.user.phoneNumber },
                ]);
            } else {
                setAccountFields([
                    { name: ['firstName'], value: globalInfo.user.firstName },
                    { name: ['lastName'], value: globalInfo.user.lastName },
                    { name: ['address'], value: globalInfo.user.address },
                    { name: ['phoneNumber'], value: '+61' },
                ]);
            }
            const managerData = await listManagerData();
            const managerDataWithKeys = managerData.map((item) => {
                let status;
                if (item.reviewStatus === "Approved") {
                    status = "Active"
                } else if (item.reviewStatus === "Rejected") {
                    status = "Rejected"
                } else if (item.reviewStatus === "Submitted") {
                    status = "Pending Application"
                } else {
                    status = item.reviewStatus;
                }

                if (item.userId === globalInfo.user.userId) {
                    return null;
                }

                const data = {
                    ...item,
                    userName: item.firstName+" "+item.lastName,
                    status: status
                }
                return data;
            }).filter(Boolean);

            const managerDataWithKeysWithIndex = managerDataWithKeys.map((item, index) => {
                return { ...item, index: index+1, key: index }
            });
            setManagerList(managerDataWithKeysWithIndex);


            const listProject = await listProjects();
            const listProjectWithIndex = listProject.map((item, index) => {
                let status;
                if (item.reviewStatus === "Approved") {
                    status = "Active"
                } else if (item.reviewStatus === "Rejected") {
                    status = "Rejected"
                } else if (item.reviewStatus === "Submitted") {
                    status = "Pending Application"
                } else if (item.reviewStatus === null) {
                    status = "Not in the project";
                } else {
                    status = item.reviewStatus;
                }

                if (status === "Active") {
                    setShowLabellingPage(true);
                } else if (globalInfo.user.userRole === "Admin") {
                    setShowLabellingPage(true);
                }

                if (item.userId === globalInfo.user.userId) {
                    return null;
                }

                const data = {
                    ...item,
                    index: index+1,
                    key: index,
                    status: status
                }
                return data;
            }).filter(Boolean);
            setProjectList(listProjectWithIndex);

            setAllImagesNum(await getAllImagesNum());
            setAllLabelledImagesNum(await getAllLabelledImagesNum());
            setAllLabelledImagesNumByUser(await getAllLabelledImagesNumByUser());

            const labelledImagesListByUserData = await getLabelledImagesListByUser();
            setLabelledImagesListByUser(labelledImagesListByUserData);
            setSelectedUsers(Object.keys(labelledImagesListByUserData));
            
            const allCountMap = await getAllUsersLabelCountMap();
            const names = Object.keys(allCountMap);
            setAllUsersLabelCountMap(allCountMap);
            setAllUsersName(names);
            const allUsersCount = names.map(name => allCountMap[name]);
            setAllUsersLabelCount(allUsersCount);
        };

        if (globalInfo.user.token) {
            fetchData();
        }
    }, [globalInfo.user]);

    const hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
          hash = hash & hash;
        }
        return hash;
    };

    const generateColor = (str, opacity = 1) => {
        const hash = hashCode(str);
        const hue = (hash * 137.508) % 360;
        return `hsl(${hue}, 50%, 50%, ${opacity})`;
      };

    const showUserManagement = () => {
        setCurrentPage("userManagement");
    }

    const showProjectList = () => {
        setCurrentPage("projectList");
    }

    // const showValidation = () => {
    //     setCurrentPage("validation");
    // }

    const showAccountDetails = () => {
        setCurrentPage("accountDetails");
    }

    const showAdminManagement = () => {
        setCurrentPage("adminManagement");
    }

    const showHistoryData = () => {
        setCurrentPage("historyData");
    }

    const rowClassName = (record, index) => {
        return index % 2 === 1 ? 'table-even-row' : 'table-odd-row';
      };

    const [currentModal, setCurrentModal] = useState('');

    const [isPermissionCheckOpen, setIsPermissionCheckOpen] = useState(false);
    const [isAccountDetailsCheckOpen, setIsAccountDetailsCheckOpen] = useState(false);

    const handleModalYes = async () => {
        if (currentModal === 'request' || currentModal === 'quit') {
            const rst = await projectApi.requestProject({
                userId: globalInfo.user.userId,
                projectId: currentApplyProject.projectId
            });
            if (rst) {
                const index = getProjectIndex(projectList, currentApplyProject);
                if (index >= 0) {
                    projectList[index].status = currentApplyProject.newStatus
                }
            }
        } else {
            setPermissionCheckLoading(true);
            const rst = await projectApi.changeUserStatus({
                userId: currentManagedUser.userId,
                mappingId: currentManagedUser.mappingId,
                reviewStatus: currentManagedUser.newStatus
            });
            if (rst) {
                const index = getIndex(managerList, currentManagedUser);
                if (index >= 0) {
                    managerList[index].status = currentManagedUser.newStatus
                }
            }
        }
        setPermissionCheckLoading(false);
        setIsPermissionCheckOpen(false);
    };

    const getIndex = (list, user) => {
        for (let i = 0; i <= list.length; i++) {
            if (user.mappingId === list[i].mappingId) {
                return i;
            }
        }
        return -1;
    }

    const getProjectIndex = (list, project) => {
        for (let i = 0; i <= list.length; i++) {
            if (project.projectId === list[i].projectId) {
                return i;
            }
        }
        return -1;
    }

    const handleModalNo = () => {
        setIsPermissionCheckOpen(false);
    };

    const handleApproveButton = (record) => {
        record.newStatus = "Approved";
        setCurrentManagedUser(record);
        setManagedUser(record.userName);
        setCurrentModal('approve');
        setIsPermissionCheckOpen(true);
    }

    const handleRejectButton = (record) => {
        record.newStatus = "Rejected";
        setCurrentManagedUser(record);
        setManagedUser(record.userName);
        setCurrentModal('reject');
        setIsPermissionCheckOpen(true);
    }

    const handleActiveButton = (record) => {
        record.newStatus = "Active";
        setCurrentManagedUser(record);
        setManagedUser(record.userName);
        setCurrentModal('active');
        setIsPermissionCheckOpen(true);
    }

    const handleDeactiveButton = (record) => {
        record.newStatus = "Deactive";
        setCurrentManagedUser(record);
        setManagedUser(record.userName);
        setCurrentModal('deactive');
        setIsPermissionCheckOpen(true);
    }

    const handleQuitButton = (record) => {
        record.newStatus = "Quit";
        setCurrentApplyProject(record);
        setAppliedProject(record.projectName);
        setCurrentModal('quit');
        setIsPermissionCheckOpen(true);
    }

    const handleRequestButton = (record) => {
        record.newStatus = "Pending Application";
        setCurrentApplyProject(record);
        setAppliedProject(record.projectName);
        setCurrentModal('request');
        setIsPermissionCheckOpen(true);
    }

    const [saveAccountDetailsLoading, setSaveAccountDetailsLoading] = useState(false);

    const saveAccountDetails = async (data) => {
        setSaveAccountDetailsLoading(true);
        const accountDetails = {
            userId: globalInfo.user.userId,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: '+'+data.phoneNumber,
            address: data.address
        }
        const rst = await userApi.updateAccountDetails(accountDetails);
        setAccountUpdateRst(rst);
        setIsAccountDetailsCheckOpen(true);
        setSaveAccountDetailsLoading(false);
    }

    const closeAccountDetailsModal = () => {
        setIsAccountDetailsCheckOpen(false);
        if (accountUpdateRst) {
            window.location.reload();
        }
    }

    const changePassword = () => {
        setIsChangePasswordModalOpen(true);
        setShowChangePasswordClose(false);
    }

    const closeChangePasswordModal = () => {
        setIsChangePasswordModalOpen(false);
    }

    function generateFilters(data, dataIndex) {
        const set = new Set();
        data.forEach((item) => {
          set.add(item[dataIndex]);
        });
        return Array.from(set).map((item) => ({ text: item.toString(), value: item.toString() }));
    }

    const checkCaptcha = async (data) => {
        const rst = await loginApi.checkCaptcha({verifyCode:data.captcha});
        return rst;
    }

    const userManagementColumns = [
        {
            title: 'No.',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Name',
            dataIndex: 'userName',
            key: 'userName',
            ...useColumnSearchProps('userName', generateFilters(managerList, 'userName')),
        },
        {
            title: 'Email Address',
            dataIndex: 'userEmail',
            key: 'userEmail',
            ...useColumnSearchProps('userEmail', generateFilters(managerList, 'userEmail')),
        },
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            ...useColumnSearchProps('role', generateFilters(managerList, 'role')),
        },
        {
            title: 'Contact Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Last Login Time',
            dataIndex: 'lastLoginTime',
            key: 'lastLoginTime',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            ...useColumnSearchProps('status', generateFilters(managerList, 'status')),
            render: (text) => {
                let color;
                switch (text) {
                    case 'Active':
                        color = 'green';
                        break;
                    case 'Deactive':
                        color = 'red';
                        break;
                    case 'Pending Application':
                        color = 'gray';
                        break;
                    default:
                        color = 'black';
                }

                return (
                    <a style={{ color: color, cursor: 'default', textDecoration: 'none' }}>
                        {text}
                    </a>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <a>
                    {record.status === 'Active' ? (
                        ((record.role === 'Manager' || record.role === 'Admin') && globalInfo.user.userRole !== 'Admin' ? (
                            <Button 
                                disabled
                                onClick={() => handleDeactiveButton(record)}
                                key='deactiveButton'>
                                Deactive
                            </Button>
                        ) : (
                            <Button 
                                onClick={() => handleDeactiveButton(record)}
                                className='deactive-button' key='deactiveButton'>
                                Deactive
                            </Button>
                        )
                    )
                    ) : record.status === 'Deactive' ? (
                        ((record.role === 'Manager' || record.role === 'Admin') && globalInfo.user.userRole !== 'Admin'? (
                                <Button 
                                    disabled
                                    onClick={() => handleActiveButton(record)}
                                    key='activeButton'>
                                    Active
                                </Button>
                            ) : (
                                <Button 
                                    onClick={() => handleActiveButton(record)}
                                    className='active-button' key='activeButton'>
                                    Active
                                </Button>
                            )
                        )
                    ) : record.status === 'Pending Application' ? (
                        <>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Button 
                                    onClick={() => handleApproveButton(record)}
                                    className='active-button' key='approveButton'>
                                    Approve
                                </Button>
                                <div style={{height: '5px'}}></div>
                                <Button 
                                    onClick={() => handleRejectButton(record)}
                                    className='deactive-button' key='rejectButton'>
                                    Reject
                                </Button>
                            </div>
                        </>
                    ): (<></>)}
                </a>
            ),
        },
    ];

    const projectListColumns = [
        {
            title: 'No.',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: 'Project Description',
            dataIndex: 'projectDesc',
            key: 'projectDesc',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (text) => {
                let color;
                switch (text) {
                    case 'Active':
                        color = 'green';
                        break;
                    case 'Rejected':
                        color = 'red';
                        break;
                    case 'Pending Application':
                        color = 'gray';
                        break;
                    case 'Deactive':
                        color = 'red';
                        break;
                    default:
                        color = 'black';
                }

                return (
                    <a style={{ color: color, cursor: 'default', textDecoration: 'none' }}>
                        {text}
                    </a>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <a>
                    {record.status === 'Active' ? (
                        <Button 
                        disabled
                            onClick={() => handleQuitButton(record)}
                            key='deactiveButton'>
                            Request
                        </Button>
                    ) : record.status === 'Rejected' || record.status === 'Not in the project' ? (
                        <Button 
                            onClick={() => handleRequestButton(record)}
                            className='active-button' key='activeButton'>
                            Request
                        </Button>
                    ) : record.status === 'Pending Application' ? (
                        <Button 
                            disabled
                            onClick={() => handleQuitButton(record)}
                            style={{width: "90px"}} key='deactiveButton'>
                            Request
                        </Button>
                    ) : record.status === 'Deactive' ? (
                        <Button 
                            disabled
                            onClick={() => handleQuitButton(record)}
                            style={{width: "90px"}} key='deactiveButton'>
                            Request
                        </Button>
                    ) : null}
                </a>
            ),
        },
    ];

    function getPast30DaysList() {
        const dates = [];
        const today = new Date();
    
        for (let i = 0; i < 30; i++) {
            const pastDate = new Date(today);
            pastDate.setDate(today.getDate() - i);
    
            const month = String(pastDate.getMonth() + 1).padStart(2, '0');
            const day = String(pastDate.getDate()).padStart(2, '0');
    
            const formattedDate = `${month}/${day}`;
    
            dates.push(formattedDate);
        }
    
        return dates.reverse();
    }

    const chartRef = useRef(null);

    const handleUserSelection = (username) => {
        setSelectedUsers((prev) =>
        prev.includes(username)
            ? prev.filter((user) => user !== username)
            : [...prev, username]
        );
    };

    const generateDatasets = () => {
        return Object.keys(labelledImagesListByUser)
            .filter((key) => selectedUsers.includes(key))
            .map((key) => ({
            label: key,
            data: labelledImagesListByUser[key],
            fill: true,
            backgroundColor: generateColor(key, 0.2),
            borderColor: generateColor(key),
            borderWidth: 2,
            tension: 0.4,
        }));
    };

    const plugin = {
        id: 'customLegend',
        afterDraw: (chart) => {
            const ctx = chart.ctx;
            ctx.save();
            const canvasPosition = chart.canvas.getBoundingClientRect();
            const legendPosition = chart.legend.legendItems;

            legendPosition.forEach((item, index) => {
                const textWidth = ctx.measureText(item.text).width;
                const xPosition = canvasPosition.left + 60;
                const yPosition = canvasPosition.top + 50 + index * 20;

                const checkbox = document.createElement('input');
                checkbox.type = 'checkbox';
                checkbox.checked = selectedUsers.includes(item.text);
                checkbox.disabled = selectedUsers.length === 1;
                checkbox.style.position = 'absolute';
                checkbox.style.left = `${xPosition}px`;
                checkbox.style.top = `${yPosition}px`;
                checkbox.addEventListener('change', () => {
                    handleUserSelection(item.text);
                });

                const colorSampleX = xPosition + 20;
                const colorSampleY = yPosition + 6;

                const keyWithColor = document.createElement('div');
                keyWithColor.style.color = item.strokeStyle;
                keyWithColor.textContent = item.text;
                keyWithColor.style.position = 'absolute';
                keyWithColor.style.width = '200px';
                keyWithColor.style.left = `${colorSampleX}px`;
                keyWithColor.style.top = `${yPosition}px`;

                if (!document.querySelector(`[data-legend-item="${item.text}"]`)) {
                    checkbox.setAttribute('data-legend-item', item.text);
                    document.body.appendChild(checkbox);
                    document.body.appendChild(keyWithColor);
                }
            });

            ctx.restore();
        },
    };

    const lineData = {
        labels: getPast30DaysList(),
        datasets: generateDatasets()
    };
    
    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Labelled photos number in past 30 days',
                font: {
                    size: 18,
                    weight: 'bold'
                },
                color: '#333'
            },
            legend: {
                display: true,
            }
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                },
            },
            y: {
                display: true,
                title: {
                    display: true,
                },
                ticks: {
                    callback: function(value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    },
                },
            },
        },
    };

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = chartRef.current;
            chartInstance.update();
        }
        if (selectedUsers.length === 1) {
            const lastCheckbox = document.querySelector(`[data-legend-item="${selectedUsers[0]}"]`)
            if (lastCheckbox !== null) {
                lastCheckbox.disabled = "true"
            }
        } else {
            for (const key of selectedUsers) {
                const lastCheckbox = document.querySelector(`[data-legend-item="${key}"]`)
                if (lastCheckbox !== null && lastCheckbox.hasAttribute("disabled")) {
                    lastCheckbox.removeAttribute("disabled")
                }
            }
        }
    }, [selectedUsers]);

    const doughnutData = {
    labels: [
        'Labelled',
        'Unlabelled',
    ],
    datasets: [{
        data: [allLabelledImagesNum, allImagesNum-allLabelledImagesNum],
        backgroundColor: [
        'Green',
        '#F2F2F2'
        ],
        hoverOffset: 4
    }]
    };

    const doughnutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'left',
                align: 'center'
            },
        },
    };

    const centerTextPlugin = {
        id: 'centerTextPlugin',
        beforeDraw: (chart) => {
            if (chart.config.type === 'doughnut') {
                const { width, height, ctx } = chart;
                ctx.restore();
                
                const total = chart.data.datasets[0].data.reduce((sum, value) => sum + value, 0);
                const value = chart.data.datasets[0].data[0]; 
                const percentage = ((value / total) * 100).toFixed(2) + '%';
                
                const fontSize = (height / 114).toFixed(2);
                ctx.font = `${fontSize}em sans-serif`;
                ctx.textBaseline = 'middle';
                
                const textX = Math.round((width - ctx.measureText(percentage).width) / 2 * 1.55);
                const textY = height / 2;
                
                ctx.fillText(percentage, textX, textY);
                ctx.save();
            }
        }
    };

    ChartJS.register(centerTextPlugin);

    const barData = {
        labels: allUsersName,
        datasets: [
            {
                label: 'Total',
                data: allUsersLabelCount,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
        ],
    };
    
    const barOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Total labelled',
                font: {
                    size: 18,
                    weight: 'bold'
                },
                position: 'left',
                color: '#333'
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    }
                }
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            }
        }
    };

    return (
    <ConfigProvider locale={en_GB}>
        { globalInfo.user.userRole && (
        <GlobalInfoProvider>
            <>
                <Layout style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
                    <div className={"header"}>
                        <div className={"logo"} onClick={() => goHomePage()}>WILD SEARCH</div>
                    </div>
                    <Header className='bg-white px-4'>
                        <div className='flex'>
                            <div className='flex-1' >
                                <div className='float-right mr-6'>
                                    <UserAvatar />
                                </div>
                            </div>
                        </div>
                    </Header>
                    <Layout >
                        <Sider width={320} style={{ background: '#e0e0e0', height: '100%'}} collapsed={collapsed} className='min-h-80'>
                            <div style={{height: '100%', paddingTop: '8px', paddingBottom: '8px'}}>
                                <div className={"accountContent-left"}>
                                    <div style={{height: '100px'}}></div>
                                    <Flex vertical='vertical'>
                                        {showLabellingPage && (
                                            <div className={"account-menu"}  onClick={jumpToLabellingPage}>
                                                <div className={'account-menu'}>
                                                    <FileImageOutlined style={{fontSize: '30px'}}/>
                                                    <div className={'account-menu-text'}>Photo Labelling</div>
                                                </div>
                                            </div>
                                        )}
                                        {showLabellingPage && (
                                            <div className={`account-menu ${currentPage === 'validation' ? 'green-text' : ''}`}  onClick={jumpToValidationPage}>
                                                <div className={'account-menu'}>
                                                    <FileDoneOutlined style={{fontSize: '30px'}}/>
                                                    <div className={'account-menu-text'}>
                                                        <span>Validation</span>
                                                    </div>
                                                </div>
                                                {currentPage === 'validation' && <RightOutlined style={{marginRight: '8px'}}/>}
                                            </div>
                                        )}
                                        <div className={`account-menu ${currentPage === 'projectList' ? 'green-text' : ''}`}  onClick={showProjectList}>
                                            <div className={'account-menu'}>
                                                <UnorderedListOutlined style={{fontSize: '30px'}}/>
                                                <div className={'account-menu-text'}>
                                                    <span>Project List</span>
                                                </div>
                                            </div>
                                            {currentPage === 'projectList' && <RightOutlined style={{marginRight: '8px'}}/>}
                                        </div>
                                        <div className={`account-menu ${currentPage === 'accountDetails' ? 'green-text' : ''}`} onClick={showAccountDetails}>
                                            <div className={'account-menu'}>
                                                <InfoCircleOutlined  style={{fontSize: '30px'}}/>
                                                <div className={'account-menu-text'}>
                                                    <span>Account Details</span>
                                                </div>
                                            </div>
                                            {currentPage === 'accountDetails' && <RightOutlined style={{marginRight: '8px'}}/>}
                                        </div>
                                        {userRole !== "User" && 
                                            <div className={`account-menu ${currentPage === 'userManagement' ? 'green-text' : ''}`}  onClick={showUserManagement}>
                                                <div className={'account-menu'}>
                                                    <UserOutlined style={{fontSize: '30px'}}/>
                                                    <div className={'account-menu-text'}>
                                                        <span>User Management</span>
                                                    </div>
                                                </div>
                                                {currentPage === 'userManagement' && <RightOutlined style={{marginRight: '8px'}}/>}
                                            </div>
                                        }
                                        <div className={`account-menu ${currentPage === 'historyData' ? 'green-text' : ''}`}  onClick={showHistoryData}>
                                            <div className={"account-menu"}>
                                                <HistoryOutlined style={{fontSize: '30px'}}/>
                                                <div className={'account-menu-text'}>History Data</div>
                                            </div>
                                            {currentPage === 'historyData' && <RightOutlined style={{marginRight: '8px'}}/>}
                                        </div>
                                        {/* <div className={`account-menu ${currentPage === 'adminManagement' ? 'green-text' : ''}`}  onClick={showAdminManagement}>
                                            <div className={"account-menu"}>
                                                <TeamOutlined style={{fontSize: '30px'}}/>
                                                <div className={'account-menu-text'}>Admin Management</div>
                                            </div>
                                            {currentPage === 'adminManagement' && <RightOutlined style={{marginRight: '8px'}}/>}
                                        </div> */}
                                    </Flex>
                                </div>
                            </div>
                        </Sider>
                        <Layout >
                            <Content style={{ background: '#e0e0e0', height: '100%', margin: '0px'}} className='ml-2 mr-2 mt-2'>
                                <div style={{paddingTop: '8px', paddingBottom: '8px', height: '100%'}}>
                                    <div className={"accountContent-right"}>
                                        {/* {
                                            currentPage === 'validation' && (
                                                <Validation/>
                                            )
                                        } */}
                                        {currentPage === 'userManagement' && (
                                            <>
                                                <div className={"accountContent-right-top"}>
                                                    <div style={{marginLeft: "15px"}}>User Management</div>
                                                </div> 
                                                <div ref={manageTableRef} className={"accountContent-right-bottom"}>
                                                    <Table 
                                                        scroll={{ y: manageTableScrollY, scrollbar: true }} 
                                                        columns={userManagementColumns} 
                                                        dataSource={managerList} 
                                                        rowClassName={rowClassName}
                                                        pagination={{ position: ["bottomRight"] }}
                                                        />
                                                </div>
                                            </>
                                        )}
                                        {currentPage === 'accountDetails' && (
                                            <>
                                                <div className={"accountContent-right-top"}>
                                                    <div style={{marginLeft: "15px"}}>Account Details</div>
                                                </div>
                                                <div className={"accountContent-right-bottom"}>
                                                    <div style={{height: '5%'}}></div>
                                                    <Form 
                                                        fields={accountFields}
                                                        onFinish={saveAccountDetails}>
                                                    <div className={'input-rows'}>
                                                        <div className={'input-rows-items-left'}>
                                                            <a className={'input-text'}>Email*:</a>
                                                            <Input style={{width: '100%', fontSize: '20px', color: 'black'}} value={globalInfo.user.emailAddress} variant="filled" disabled />
                                                        </div>
                                                        <div className={'input-rows-items-right'}>
                                                            <a className={'input-text'}> Phone Number*:</a>
                                                            <Form.Item name="phoneNumber" style={{fontSize: '20px'}} 
                                                                rules={[
                                                                    () => ({
                                                                        validator(_, value) {
                                                                            const numberRegex = /\d{4,}/;
                                                                            if (value && numberRegex.test(value)) {
                                                                                setIsPhoneCheckSuccess(true);
                                                                                return Promise.resolve();
                                                                            }
                                                                            setIsPhoneCheckSuccess(false);
                                                                            return Promise.reject(new Error('The phone number is invalid!'));
                                                                        }
                                                                    })
                                                                ]}>
                                                                <PhoneInput
                                                                    country="au"
                                                                    containerClass={isPhoneCheckSuccess ? 'PhoneInput-account-normal' : 'PhoneInput-account-error'}
                                                                    inputClass={'PhoneInput-account-input'}
                                                                    specialLabel=""
                                                                    />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className={'input-rows'}>
                                                        <div className={'input-rows-items-left'}>
                                                            <a className={'input-text'}>First Name*:</a>
                                                            <Form.Item name="firstName" rules={[{ required: true, message: "The first name cannot be empty!" }]}>
                                                                <Input 
                                                                    style={{width: '100%', fontSize: '20px'}} 
                                                                    placeholder="Please input your first name..."/>
                                                            </Form.Item>
                                                        </div>
                                                        <div className={'input-rows-items-right'}>
                                                            <a className={'input-text'}>Last Name*:</a>
                                                            <Form.Item name="lastName" rules={[{ required: true, message: "The last name cannot be empty!" }]}>
                                                                <Input 
                                                                    style={{width: '100%', fontSize: '20px'}} 
                                                                    placeholder="Please input your last name..."/>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className={'input-rows'} style={{flex: 1}}>
                                                        <div className={'input-address'}>
                                                            <a className={'input-text'}>Address*:</a>
                                                            <Form.Item name="address" rules={[{ required: true, message: "The address cannot be empty!" }]}>
                                                            <TextArea 
                                                                style={{width: '100%', height: '100%', marginBottom: '20px', fontSize: '20px'}} 
                                                                placeholder="Please input your address..." 
                                                                variant="filled"/>
                                                            </Form.Item>
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                <Button className={'save-details-change-button'} onClick={changePassword} loading={saveAccountDetailsLoading}>
                                                                        Change Password
                                                                </Button>
                                                                <Space></Space>
                                                                <Form.Item name="button">
                                                                    <Button className={'save-details-change-button'} htmlType="submit" loading={saveAccountDetailsLoading}>
                                                                        Save Changes
                                                                    </Button>
                                                                </Form.Item>
                                                            </div>
                                                            <Modal
                                                                    title = {
                                                                        <div className={'modal-title-container'}>
                                                                            {accountUpdateRst === false && <InfoCircleOutlined  style={{color: 'orange', paddingRight: '5px'}}/>}
                                                                            {accountUpdateRst === true && <CheckCircleOutlined  style={{color: 'green', paddingRight: '5px'}}/>}
                                                                            <span className={'modal-title-text'}>Save Result</span>
                                                                        </div>
                                                                    }
                                                                    centered
                                                                    open={isAccountDetailsCheckOpen}
                                                                    onCancel={closeAccountDetailsModal}
                                                                    footer={[
                                                                        <Button className={'warning-modal-yes-button'}
                                                                            key="ok" type="primary" onClick={closeAccountDetailsModal}>
                                                                            OK
                                                                        </Button>,
                                                                    ]}>
                                                                {accountUpdateRst === true && <p>Save Success.</p>}
                                                                {accountUpdateRst === false && <p>Save Failed.</p>}
                                                            </Modal>
                                                            <Modal
                                                                    title = {
                                                                        <div className={'modal-title-container'}>
                                                                            <InfoCircleOutlined  style={{color: 'orange', paddingRight: '5px'}}/>
                                                                            <span className={'modal-title-text'}>Change Password</span>
                                                                        </div>
                                                                    }
                                                                    centered
                                                                    open={isChangePasswordModalOpen}
                                                                    onCancel={closeChangePasswordModal}
                                                                    footer={null}
                                                                    >
                                                                <Form 
                                                                    onFinish={async (data) => {
                                                                        setIsChangePasswordLoading(true);
                                                                        const rst = await checkCaptcha(data);
                                                                        setCheckCaptchaRst(rst);
                                                                        if (rst) {
                                                                            const pwdData = {
                                                                                oldPwd: data.oldPassword,
                                                                                newPwd: data.newPassword
                                                                            }
                                                                            const changeRst = await userApi.changePassword(pwdData);
                                                                            if (!changeRst) {
                                                                                setIsOldPasswordCorrect(false);
                                                                            } else {
                                                                                setShowChangePasswordClose(true);
                                                                            }
                                                                        }
                                                                        setIsChangePasswordLoading(false);
                                                                    }} style={{width: '100%'}}>
                                                                        <div style={{marginTop: '20px'}}></div>
                                                                        <div>Old Password:</div>
                                                                        <Form.Item name="oldPassword" 
                                                                        validateStatus={isOldPasswordCorrect ? 'success' : 'error'}
                                                                        help={isOldPasswordCorrect ? null : "The old password is incorrect!"}
                                                                        rules={[
                                                                            { required: true, message: "The old password cannot be empty!" },
                                                                            () => ({
                                                                                validator(_, value) {
                                                                                    setIsOldPasswordCorrect(true);
                                                                                    return Promise.resolve();
                                                                                }
                                                                            })
                                                                            ]}>
                                                                            <Input.Password className={'login-input'} placeholder="Please enter your old password." />
                                                                        </Form.Item>
                                                                        <div>New Password:</div>
                                                                        <Form.Item name="newPassword" rules={[
                                                                            { required: true, message: "The new password cannot be empty!" },
                                                                            ]}>
                                                                            <Input.Password className={'login-input'} placeholder="Please enter your new password." />
                                                                        </Form.Item>
                                                                        <div>Confirm New Password:</div>
                                                                        <Form.Item name="confirmNewPassword" rules={[
                                                                            { required: true, message: "The new password cannot be empty!" },
                                                                            ({getFieldValue}) => ({
                                                                                validator(_, value) {
                                                                                    if (!value || getFieldValue('newPassword') === value) {
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                    return Promise.reject(new Error('The two passwords that you entered do not match.'));
                                                                                }
                                                                            })
                                                                            ]}>
                                                                            <Input.Password className={'login-input'} placeholder="Please confirm your new password." />
                                                                        </Form.Item>
                                                                        <div>Verification Code:</div>
                                                                        <div className={'captchaInputDiv'}>
                                                                            <Form.Item name="captcha" 
                                                                                validateStatus={checkCaptchaRst ? 'success' : 'error'}
                                                                                help={checkCaptchaRst ? null : "The verification code is incorrect!"}
                                                                                rules={[
                                                                                { required: true, message: "The verification code cannot be empty!" },
                                                                                () => ({
                                                                                    validator(_, value) {
                                                                                        setCheckCaptchaRst(true);
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                })
                                                                                ]}>
                                                                                    <Input className={'login-input'} style={{width: '130%'}} placeholder="Verification Code" />
                                                                            </Form.Item>
                                                                            <img 
                                                                                id='captchaImage'
                                                                                style={{height: '40px', cursor: 'pointer'}} 
                                                                                src={BASE_URL+"/public/user/vc.jpg"}
                                                                                onClick={() => {
                                                                                    const img = document.getElementById('captchaImage');
                                                                                    if (img) {
                                                                                        const src = img.getAttribute('src');
                                                                                        if (src) {
                                                                                            var time=new Date().getTime();
                                                                                            img.setAttribute('src', src+'?'+time);
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{marginTop: '20px'}}></div>
                                                                        <Form.Item name="button">
                                                                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                                                                <div style={{display: showChangePasswordClose ? 'none' : 'inline'}} className={'login-signup-text'} ></div>
                                                                                <Button 
                                                                                    style={{display: showChangePasswordClose ? 'none' : 'inline'}}
                                                                                    className="login-button" 
                                                                                    type="primary" 
                                                                                    htmlType="submit" 
                                                                                    loading={isChangePasswordLoading}>
                                                                                        Submit
                                                                                </Button>
                                                                                <div style={{display: showChangePasswordClose ? 'inline' : 'none'}}>
                                                                                    <div style={{marginTop: '5%'}}></div>
                                                                                    <span>Password reset complete!</span>
                                                                                </div>
                                                                                <Button 
                                                                                    style={{display: showChangePasswordClose ? 'inline' : 'none'}}
                                                                                    className="login-button" 
                                                                                    type="primary"
                                                                                    onClick={closeChangePasswordModal}>
                                                                                        Close
                                                                                    </Button>
                                                                            </div>
                                                                        </Form.Item>
                                                                    </Form>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                    </Form>
                                                </div>
                                            </>
                                        )}
                                        {currentPage === 'projectList' && (
                                            <>
                                                <div className={"accountContent-right-top"}>
                                                    <div style={{marginLeft: "15px"}}>Project List</div>
                                                </div>
                                                <div ref={projectTableRef} className={"accountContent-right-bottom"}>
                                                    <Table 
                                                        scroll={{ y: projectTableScrollY, scrollbar: true }} 
                                                        pagination={{ position: ["bottomRight"] }}
                                                        columns={projectListColumns} 
                                                        dataSource={projectList} 
                                                        rowClassName={rowClassName}/>
                                                </div>
                                            </>
                                        )}
                                        {currentPage === 'historyData' && (
                                            <>
                                                <div className={"accountContent-right-top"}>
                                                    <div style={{marginLeft: "15px"}}>History Data</div>
                                                </div>
                                                <div ref={projectTableRef} className={"accountContent-right-bottom"}>
                                                    <div style={{height: "28%", display: "flex", marginTop: "2%"}}>
                                                        <div style={{width: "300px", marginRight: '5%', marginLeft: '5%'}}>
                                                            <Doughnut data={doughnutData} options={doughnutOptions}/>
                                                        </div>
                                                        <div style={{width: '200px', height: "100%"}}>
                                                            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                                                                <span className={'history-titles'}>Total Photos: {allImagesNum}</span>
                                                                <span className={'history-titles'}>Total Labelled: {allLabelledImagesNum}</span>
                                                                <span className={'history-titles'}>Your Total Labelled: {allLabelledImagesNumByUser}</span>
                                                            </div>
                                                        </div>
                                                        {/* {allUsersLabelCount.length > 0 ? (
                                                            <div style={{marginLeft: '5%'}}>
                                                                <Bar data={barData} options={barOptions} />
                                                            </div>
                                                        ) : (
                                                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                                                            </div>
                                                        )} */}
                                                    </div>
                                                    <div style={{height: "65%"}}>
                                                        {selectedUsers.length > 0 ? (
                                                            <Line ref={chartRef} data={lineData} options={lineOptions} style={{marginLeft: '30px', marginRight: '30px'}}/>
                                                        ) : (
                                                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                                                                <div className="loading"></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Content>
                        </Layout>
                        <Modal
                            title = {
                                <div className={'modal-title-container'}>
                                    <InfoCircleOutlined  style={{color: 'orange', paddingRight: '5px'}}/>
                                    <span className={'modal-title-text'}>Warning</span>
                                </div>
                                    }
                            open = {isPermissionCheckOpen}
                            centered
                            onCancel={handleModalNo}
                            footer={[
                                <Button className={'warning-modal-no-button'} key="no" onClick={handleModalNo}>
                                    No
                                </Button>,
                                <Button className={'warning-modal-yes-button'}
                                    key="yes" type="primary" loading={permissionCheckLoading} onClick={handleModalYes}>
                                    YES
                                </Button>,
                            ]}
                        >   
                            {currentModal === 'approve' && <p>Do you want to approve {managedUser}'s application?</p>}
                            {currentModal === 'reject' && <p>Do you want to reject {managedUser}'s application?</p>}
                            {currentModal === 'active' && <p>Do you want to active {managedUser}'s permission?</p>}
                            {currentModal === 'deactive' && <p>Do you want to deactive {managedUser}'s permission?</p>}
                            {currentModal === 'request' && <p>Do you want to apply for the {appliedProject}?</p>}
                            {currentModal === 'quit' && <p>Do you want to quit {appliedProject}?</p>}
                        </Modal>
                    </Layout>
                    <Footer className='text-center'>
                        ParrotsLabelling ©2024 Created by WildSearch
                    </Footer>
                </Layout>
            </>
        </GlobalInfoProvider>
        )}
    </ConfigProvider>
    
)}
